.App {
  a {
    text-decoration: none;
  }
  a:nth-child(1) {
    writing-mode: vertical-lr;
    font-size: 70px;
    margin-left: 12px;
    color: #e9ce87;
    transform: rotate(-45deg);
  }
  a:nth-child(2) {
    writing-mode: horizontal-tb;
    font-size: 50px;
    margin-left: 12px;
    color: #cd9338;
  }
  a:nth-child(3) {
    writing-mode: vertical-lr;
    font-size: 30px;
    margin-left: 12px;
    color: #6e6449;
  }
  a:nth-child(4) {
    writing-mode: horizontal-tb;
    font-size: 60px;
    margin-left: 12px;
    color: #cd9338;
    transform: rotate(-45deg);
  }
  a:nth-child(5) {
    writing-mode: vertical-lr;
    font-size: 30px;
    margin-left: 12px;
    color: #c6710a;
  }
  a:nth-child(6) {
    writing-mode: vertical-lr;
    font-size: 50px;
    margin-left: 12px;
    color: #e9ce87;
    transform: rotate(-45deg);
  }
  a:nth-child(7) {
    writing-mode: vertical-lr;
    font-size: 20px;
    margin-left: 12px;
    color: #6e6449;
    transform: rotate(-45deg);
  }
  a:nth-child(8) {
    writing-mode: vertical-lr;
    font-size: 80px;
    margin-left: 12px;
    color: #e9ce87;
  }
  a:nth-child(9) {
    writing-mode: horizontal-tb;
    font-size: 40px;
    margin-left: 12px;
    color: #c6710a;
  }
}
